<template>
  <div class="worksheet">
    <table class="demographics" id="demographics">
      <tr>
        <td class="strong">Project ID:</td>
        <td colspan="3">{{ worksheet.projectId }}</td>
      </tr>
      <tr>
        <td class="strong">Hospital:</td>
        <td>{{ worksheet.clientName }}</td>
        <td class="strong">Review Date:</td>
        <td>{{ formatDate(worksheet.reviewDate) }}</td>
      </tr>
      <tr>
        <td class="strong">Auditor:</td>
        <td>{{ worksheet.reviewerName }}</td>
        <td class="strong">Patient Account Number:</td>
        <td>{{ worksheet.patAcctNum }}</td>
      </tr>
    </table>

    <table class="demographics" style="margin-top: -1px;">
      <template v-if="worksheet.patientAge === 0">
        <tr>
          <td class="strong">Patient Age:</td>
          <td>{{ worksheet.patientAge }}</td>
          <td class="strong">Patient Sex:</td>
          <td>{{ worksheet.patientSex }}</td>
          <td class="strong">Patient DOB:</td>
          <td>{{ formatDate(worksheet.patientDob) }}</td>
        </tr>
        <tr>
          <td class="strong">Newborn Weight:</td>
          <td>{{ worksheet.newbornWeight ? `${worksheet.newbornWeight}g` : null }}</td>
          <td class="strong">Medical Record Number:</td>
          <td>{{ worksheet.mrNumber }}</td>
          <td class="strong">Coder Query:</td>
          <td>{{ worksheet.coderQuery ? 'Yes' : 'No' }}</td>
        </tr>
        <tr>
          <td class="strong">Coder Query Missed:</td>
          <td>{{ worksheet.coderQueryMissed ? 'Yes' : 'No' }}</td>
          <td class="strong">Coder:</td>
          <td>{{ worksheet.coder }}</td>
          <td class="strong">Reviewer Query:</td>
          <td>{{ worksheet.reviewerQuery ? 'Yes' : 'No' }}</td>
        </tr>
        <tr>
          <td class="strong">Payor: <br />Payor Detail:</td>
          <td style="vertical-align: top;">{{ worksheet.payorName }}<br />{{ worksheet.payorDetail }}</td>
          <td class="strong" v-if="worksheet.outpatientGrouperId === 1">APC Change:</td>
          <td class="strong" v-if="worksheet.outpatientGrouperId === 2">Financial Impact:</td>
          <td>{{ worksheet.financialImpact ? 'Yes' : 'No' }}</td>
          <td class="strong">Date of Service:</td>
          <td>{{ formatDate(worksheet.dateOfService) }}</td>
        </tr>
        <tr>
          <td class="strong">Physician:</td>
          <td>{{ worksheet.physician }}</td>
          <td class="strong">Reason for Change:</td>
          <td>{{ worksheet.reasonForChange }}</td>
          <td class="strong"></td>
          <td></td>

          <!-- <td class="strong">UB Available:</td>
        <td>{{worksheet.noUBAvailable ? 'No' : 'Yes'}}</td> -->
        </tr>
      </template>
      <template v-else>
        <tr>
          <td class="strong">Patient Age:</td>
          <td>{{ worksheet.patientAge }}</td>
          <td class="strong">Patient Sex:</td>
          <td>{{ worksheet.patientSex }}</td>
          <td class="strong">Medical Record Number:</td>
          <td>{{ worksheet.mrNumber }}</td>
        </tr>
        <tr>
          <td class="strong">Coder Query:</td>
          <td>{{ worksheet.coderQuery ? 'Yes' : 'No' }}</td>
          <td class="strong">Coder Query Missed:</td>
          <td>{{ worksheet.coderQueryMissed ? 'Yes' : 'No' }}</td>
          <td class="strong">Coder:</td>
          <td>{{ worksheet.coder }}</td>
        </tr>
        <tr>
          <td class="strong">Reviewer Query:</td>
          <td>{{ worksheet.reviewerQuery ? 'Yes' : 'No' }}</td>
          <td class="strong">Payor: <br />Payor Detail:</td>
          <td style="vertical-align: top;">{{ worksheet.payorName }}<br />{{ worksheet.payorDetail }}</td>
          <td class="strong" v-if="worksheet.outpatientGrouperId === 1">APC Change:</td>
          <td class="strong" v-if="worksheet.outpatientGrouperId === 2">Financial Impact:</td>
          <td>{{ worksheet.financialImpact ? 'Yes' : 'No' }}</td>
        </tr>
        <tr>
          <td class="strong">Date of Service:</td>
          <td>{{ formatDate(worksheet.dateOfService) }}</td>
          <td class="strong">Physician:</td>
          <td>{{ worksheet.physician }}</td>
          <td class="strong">Reason for Change:</td>
          <td>{{ worksheet.reasonForChange }}</td>
        </tr>
        <tr>
          <!-- <td class="strong">UB Available:</td>
        <td>{{worksheet.noUBAvailable ? 'No' : 'Yes'}}</td> -->
        </tr>
      </template>
    </table>

    <table>
      <tr style="background-color: lightslategray; color: white; text-align: center;">
        <td style="width: 45%;">Original Dx Coding</td>
        <td style="width: 55%;">Recommended Dx Coding</td>
      </tr>
    </table>

    <table class="stripe">
      <thead>
        <tr style="text-align: left;">
          <th style="width: 8%;">Code</th>
          <th style="width: 25%;">Description</th>
          <th style="width: 7%">HCC</th>
          <th style="width: 8%;">Code</th>
          <th style="width: 25%;">Description</th>
          <th style="width: 7%">HCC</th>
          <th style="width: 10%">Reason</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="codeType in getCodeTypes(worksheet.dxs)">
          <tr :key="codeType">
            <td colspan="7" class="codeTypeHeader">{{ codeType }}</td>
          </tr>
          <tr :class="{ bold: isDxRowChange(dx) }" v-for="dx in worksheet.dxs.filter(x => x.codeDesc === codeType)" :key="dx.dxId">
            <td style="width: 5%;" :class="{ 'red-font': isOriginalRedFont(dx, worksheet.dxs) }">
              {{ dx.originalDx }}
            </td>
            <td :class="{ 'red-font': isOriginalRedFont(dx, worksheet.dxs) }">
              {{ dx.originalDescription }} <span v-if="dx.originalGrouperFlags" class="grouperFlags">({{ dx.originalGrouperFlags }})</span>
            </td>
            <td :class="{ 'red-font': isOriginalRedFont(dx, worksheet.dxs) }" style="font-size: 13px;">
              <div>{{ dx.originalHccs }} </div>
              <div>{{dx.originalHccsV28}}</div>
            </td>
            <td :class="{ 'red-font': isRecommendedRedFont(dx, worksheet.dxs) }" :style="dx.originalDeleted ? 'text-decoration: line-through' : ''">
              {{ dx.recommendedDx }}
            </td>
            <td :class="{ 'red-font': isRecommendedRedFont(dx, worksheet.dxs) }">
              {{ getDxRecommendedDescription(dx, worksheet.dxs) }} <span
                v-if="(dx.recommendedDx && dx.recommendedGrouperFlags) || (dx.recommendedGrouperFlags && dx.recommendedGrouperFlags !== dx.originalGrouperFlags)"
                class="grouperFlags">({{ dx.recommendedGrouperFlags }})</span>
            </td>
            <td :class="{ 'red-font': isRecommendedRedFont(dx, worksheet.dxs) }" style="font-size: 13px;">
              <div>{{ dx.recommendedHccs }} </div>
              <div>{{dx.recommendedHccsV28}}</div>
            </td>
            <td :class="{ 'red-font': isRecommendedRedFont(dx, worksheet.dxs) }">{{ dx.reasonForChange }}</td>
          </tr>
        </template>
      </tbody>
    </table>

    <template v-if="worksheet.pxs.length > 0">
      <table id="pxCodes">
        <tr style="background-color: lightslategray; color: white; text-align: center;">
          <td style="width: 45%;">Original Px Coding</td>
          <td style="width: 55%;">Recommended Px Coding</td>
        </tr>
      </table>

      <table class="stripe">
        <thead>
          <tr style="text-align: left;">
            <th style="width: 10%;">Code</th>
            <th style="width: 35%;">Description</th>
            <th style="width: 10%;">Code</th>
            <th style="width: 35%;">Description</th>
            <th style="width: 10%">Reason</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="codeType in getCodeTypes(worksheet.pxs)">
            <tr :key="codeType">
              <td colspan="5" class="codeTypeHeader">{{ codeType }}</td>
            </tr>
            <tr :class="{ 'red-font': px.originalDeleted, bold: isPxRowChange(px) }" v-for="px in worksheet.pxs.filter(x => x.codeDesc === codeType)" :key="px.pxId">
              <td>
                {{ px.originalPx }}
                <span v-if="px.procedureDate" style="display: inline-block; font-style: italic; margin-top: 5px; font-size: 13px;">({{ formatDate(px.procedureDate) }})</span>
              </td>
              <td>
                {{ px.originalDescription }} <span v-if="px.originalGrouperFlags" class="grouperFlags">({{ px.originalGrouperFlags }})</span>
              </td>
              <td :style="px.originalDeleted ? 'text-decoration: line-through' : ''">{{ px.originalDeleted ? px.originalPx : px.recommendedPx }}</td>
              <td>
                {{ px.originalDeleted ? getPxDeletedDescription(px) : px.recommendedDesc }} <span
                  v-if="(px.recommendedPx && px.recommendedGrouperFlags) || (px.recommendedGrouperFlags && px.recommendedGrouperFlags !== px.originalGrouperFlags)"
                  class="grouperFlags">({{ px.recommendedGrouperFlags }})</span>
              </td>
              <td>{{ px.reasonForChange }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </template>

    <template v-if="worksheet.cpts.length > 0">
      <table id="cptCodes">
        <tr style="background-color: lightslategray; color: white; text-align: center;">
          <td style="width: 45%;">Original CPT Coding</td>
          <td style="width: 55%;">Recommended CPT Coding</td>
        </tr>
      </table>

      <table class="stripe">
        <thead>
          <tr style="text-align: left;">
            <th style="width: 10%;">Code</th>
            <th style="width: 35%;">Description</th>
            <th style="width: 10%;">Code</th>
            <th style="width: 35%;">Description</th>
            <th style="width: 10%">Reason</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="codeType in getCodeTypes(worksheet.cpts)">
            <tr :key="codeType">
              <td colspan="5" class="codeTypeHeader">{{ codeType }}</td>
            </tr>
            <tr :class="{ 'red-font': cpt.originalDeleted, bold: isCptRowChange(cpt) }" v-for="cpt in worksheet.cpts.filter(x => x.codeDesc === codeType)" :key="cpt.cptId">
              <td>
                {{ cpt.originalCpt }}{{ cpt.originalModifierCalc }}
                <span v-if="cpt.procedureDate" style="display: inline-block; font-style: italic; margin-top: 5px; font-size: 13px;">({{ formatDate(cpt.procedureDate)
                  }})</span>
              </td>
              <td>
                {{ cpt.originalDescription }}
              </td>
              <td :style="cpt.originalDeleted ? 'text-decoration: line-through' : ''">
                <template v-if="cpt.originalDeleted"> {{ cpt.originalCpt }}{{ cpt.originalModifierCalc }} </template>
                <template v-else> <span v-html="cpt.calcRecCPT"></span> </template>
              </td>
              <td>
                {{ cpt.originalDeleted ? getCptDeletedDescription(cpt) : cpt.recommendedDesc }}
              </td>
              <td>{{ cpt.reasonForChangeDescription }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </template>

    <template>
      <table class="" id="findings">
        <tr style="background-color: lightslategray; color: white; text-align: center;">
          <th style="width: 100%;">Findings</th>
        </tr>
      </table>

      <table>
        <tr>
          <td width="95%">
            <OutpatientWorksheetFindings :findings="worksheet.findings"> </OutpatientWorksheetFindings>
          </td>
        </tr>
      </table>
    </template>

    <table class="demographics" width="100%" id="coderResponses" v-if="!isCoder && !optionalParameters.hideWorksheetCoderResponses">
      <tbody>
        <tr style="background-color: lightslategray; color: white; text-align: center;">
          <th colspan="6">Coder Responses</th>
        </tr>
        <tr v-if="!worksheet.rebuttal">
          <td colspan="6">
            <div style="font-style: italic; font-size: 14px;">No Responses on Worksheet</div>
          </td>
        </tr>
        <template v-if="worksheet.rebuttal">
          <tr>
            <td class="strong">
              Response Date:
            </td>
            <td>
              {{ formatDate(worksheet.rebuttal.rebuttalDate) }}
            </td>
            <td class="strong">
              Coder Agrees With Changes:
            </td>
            <td>
              {{ worksheet.rebuttal.agreeWithCodes ? 'Yes' : 'No' }}
            </td>
            <td class="strong">
              Final Outcome:
            </td>
            <td>
              {{ worksheet.rebuttal.finalOutcome }}
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div class="strong" style="margin-bottom: 20px;">Response Comments:</div>
              <div style="margin: 0px 20px 0px 20px;" v-for="comment in worksheet.rebuttal.rebuttalComments" :key="comment.commentId">
                {{ comment.user.fullName }} on <i>{{ format(parseISO(comment.createdDate), 'M/d/yyyy hh:mm a') }}</i>
                <div class="commentRichText" v-html="comment.comment"></div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
  import dateHelper from '@/mixins/date-helper'
  import { format, parseISO } from 'date-fns'
  import OutpatientWorksheetFindings from './OutpatientWorksheetFindings.vue'

  export default {
    name: 'InpatientWorksheet',
    mixins: [dateHelper],
    props: {
      worksheet: {
        type: Object
      },
      isCoder: {
        type: Boolean
      },
      optionalParameters: {
        type: Object,
        default: function () {
          return {}
        }
      }
    },
    components: {
      OutpatientWorksheetFindings
    },
    data() {
      return {
        format: format,
        parseISO: parseISO
      }
    },
    methods: {
      isDxRowChange(dx) {
        if (dx.recommendedDx || dx.originalDeleted || dx.recommendedPOA) {
          return true
        }
        return false
      },
      isPxRowChange(px) {
        if (px.recommendedPx || px.originalDeleted) {
          return true
        }
        return false
      },
      isCptRowChange(cpt) {
        if (cpt.recommendedCpt || cpt.calcRecCPT || cpt.originalDeleted) {
          return true
        }
        return false
      },
      getCodeTypes(arr) {
        const codeTypes = [...new Set(arr.map(x => x.codeDesc))]
        return codeTypes.sort((a, b) => (a > b ? 1 : -1))
      },
      getDxRecommendedDescription(dx, dxs) {
        if (dx.originalDeleted) {
          if (dx.recommendedDesc) {
            return `Delete Diagnosis - ${dx.recommendedDesc}`
          }
          return 'Delete Diagnosis'
        }

        if (dx.recommendedDesc && dx.rowNumber === 1 && dxs.some(x => x.newPrimary)) {
          if (dx.recommendedDesc) {
            return `Resequence Principal Diagnosis - ${dx.recommendedDesc}`
          }
          return 'Resequence Principal Diagnosis'
        }

        return dx.recommendedDesc
      },
      getPxDeletedDescription(px) {
        if (px.originalDescription) {
          return `Delete Procedure - ${px.originalDescription}`
        }
        return 'Delete Procedure'
      },
      getCptDeletedDescription(cpt) {
        if (cpt.originalDescription) {
          return `Delete CPT - ${cpt.originalDescription}`
        }
        return 'Delete CPT'
      },
      isOriginalRedFont(dx, dxs) {
        if (dx.rowNumber === 1 && dxs.some(x => x.newPrimary && x.originalDeleted)) {
          return true
        }

        if (dx.originalDeleted && !dxs.some(x => x.newPrimary)) {
          return true
        }
        return false
      },
      isRecommendedRedFont(dx, dxs) {
        return dx.originalDeleted
      },
      heys(cpt) {
        console.log(cpt)
        return 'yoooooo'
      }
    }
  }
</script>

<style scoped>
  .commentRichText {
    border: 1px solid #dcdfe6;
    background: #e5eef4;
    border-radius: 4px;
    padding: 5px;
    margin: 10px 0 20px 0px;
    min-height: 30px;
  }

  .strong {
    font-weight: bold;
  }

  .separator {
    height: 15px;
    background-color: lightslategray;
  }

  .codeTypeHeader {
    text-align: left;
    padding: 4px 0px 4px 5px;
    border-left: 8px solid #3378aa7c;
    font-style: italic;
  }

  .red-font {
    color: red;
  }

  .bold {
    font-weight: bold;
  }

  tr:nth-child(odd) {
    background: unset;
  }

  .worksheet thead {
    /* background-color: unset !important; */
  }

  .grouperFlags {
    font-style: italic;
  }

  >>>.delCode {
    color: red;
    text-decoration: line-through;
  }
</style>