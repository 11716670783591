<template>
  <div class="worksheet">
    <table class=" demographics" id="demographics">
      <tr>
        <td class="strong">Project ID:</td>
        <td colspan="3">{{ worksheet.projectId }}</td>
      </tr>
      <tr>
        <td class="strong">Facility:</td>
        <td>{{ worksheet.clientName }}</td>
        <td class="strong">Review Date:</td>
        <td>{{ formatDate(worksheet.reviewDate) }}</td>
      </tr>
      <tr>
        <td class="strong">Auditor:</td>
        <td>{{ worksheet.reviewerName }}</td>
        <td class="strong">Patient Account Number:</td>
        <td>{{ worksheet.patientNumber }}</td>
      </tr>
    </table>

    <table class=" demographics" style="margin-top: -1px;">
      <tr>
        <td class="strong">Place of Service</td>
        <td>{{ worksheet.placeOfService }}</td>
        <td class="strong">EM Guidelines</td>
        <td>{{ worksheet.emGuidelines }}</td>
        <td class="strong">Medical Record Number:</td>
        <td>{{ worksheet.mrnumber }}</td>
      </tr>
      <tr>
        <td class="strong">Payor: <br />Payor Detail:</td>
        <td style="vertical-align: top;">{{ worksheet.payorName }}<br />{{ worksheet.payorDetail }}</td>
        <td class="strong">Date of Service:</td>
        <td>{{ formatDate(worksheet.dateOfService) }}</td>
        <td class="strong">Coder:</td>
        <td>{{ worksheet.coder }}</td>
      </tr>
      <tr>
        <td class="strong">Patient Sex:</td>
        <td>{{ worksheet.patientSex }}</td>
        <td class="strong">Provider:</td>
        <td>{{ worksheet.physicianName }}</td>
        <td class="strong">Reason for Change:</td>
        <td>{{ worksheet.reasonForChange }}</td>
      </tr>
      <tr v-if="worksheet.patientAge === 0">
        <td class="strong">Patient DOB:</td>
        <td>{{ formatDate(worksheet.patientDob) }}</td>
        <td class="strong"></td>
        <td></td>
        <td class="strong"></td>
        <td></td>
      </tr>
      <tr v-if="worksheet.patientAge === 0">
        <td class="strong">Newborn Weight:</td>
        <td>{{ worksheet.newbornWeight ? `${worksheet.newbornWeight}g` : null }}</td>
        <td class="strong"></td>
        <td></td>
        <td class="strong"></td>
        <td></td>
      </tr>
    </table>

    <table class=" ">
      <tr style="background-color: lightslategray; color: white; text-align: center;">
        <td style="width: 45%;">Original Dx Coding</td>
        <td style="width: 55%;">Recommended Dx Coding</td>
      </tr>
    </table>

    <table class=" stripe">
      <thead>
        <tr style="text-align: left;">
          <th style="width: 8%;">Code</th>
          <th style="width: 30%;">Description</th>
          <th style="width: 7%">HCC</th>
          <th style="width: 8%;">Code</th>
          <th style="width: 30%;">Description</th>
          <th style="width: 7%">HCC</th>
          <th style="width: 10%">Reason</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="codeType in getCodeTypes(worksheet.dxs)">
          <tr :key="codeType">
            <td colspan="7" class="codeTypeHeader">
              <div>{{ codeType }}</div>
            </td>
          </tr>
          <tr :class="{ bold: isDxRowChange(dx) }" v-for="dx in worksheet.dxs.filter(x => x.codeDesc === codeType)" :key="dx.dxId">
            <td style="width: 5%;" :class="{ 'red-font': isOriginalRedFont(dx, worksheet.dxs) }">
              {{ dx.originalDx }}
            </td>
            <td :class="{ 'red-font': isOriginalRedFont(dx, worksheet.dxs) }">
              {{ dx.originalDescription }} <span v-if="dx.originalGrouperFlags" class="grouperFlags">({{ dx.originalGrouperFlags }})</span>
            </td>
            <td :class="{ 'red-font': isOriginalRedFont(dx, worksheet.dxs) }" style="font-size: 13px;">
              <div>{{ dx.originalHccs }} </div>
              <div>{{dx.originalHccsV28}}</div>
            </td>
            <td :class="{ 'red-font': isRecommendedRedFont(dx, worksheet.dxs) }" :style="dx.originalDeleted ? 'text-decoration: line-through' : ''">
              {{ dx.recommendedDx }}
            </td>
            <td :class="{ 'red-font': isRecommendedRedFont(dx, worksheet.dxs) }">
              {{ getDxRecommendedDescription(dx, worksheet.dxs) }} <span
                v-if="(dx.recommendedDx && dx.recommendedGrouperFlags) || (dx.recommendedGrouperFlags && dx.recommendedGrouperFlags !== dx.originalGrouperFlags)"
                class="grouperFlags">({{ dx.recommendedGrouperFlags }})</span>
            </td>
            <td :class="{ 'red-font': isRecommendedRedFont(dx, worksheet.dxs) }" style="font-size: 13px;">
              <div>{{ dx.recommendedHccs }} </div>
              <div>{{dx.recommendedHccsV28}}</div>
            </td>
            <td :class="{ 'red-font': isRecommendedRedFont(dx, worksheet.dxs) }">{{ dx.reasonForChange }}</td>
          </tr>
        </template>
      </tbody>
    </table>

    <table class="" id="pxCodes">
      <tr style="background-color: lightslategray; color: white; text-align: center;">
        <td style="width: 45%;">Original CPT Coding</td>
        <td style="width: 55%;">Recommended CPT Coding</td>
      </tr>
    </table>

    <table class=" stripe">
      <thead>
        <tr style="text-align: left;">
          <th style="width: 10%;">Code</th>
          <th style="width: 35%;">Description</th>
          <th style="width: 10%;">Code</th>
          <th style="width: 35%;">Description</th>
          <th style="width: 10%">Reason</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="codeType in getCodeTypes(worksheet.cpts)">
          <tr :key="codeType">
            <td colspan="5" class="codeTypeHeader">
              <div>{{ codeType }}</div>
            </td>
          </tr>
          <tr :class="{ 'red-font': px.originalDeleted, bold: isPxRowChange(px) }" v-for="px in worksheet.cpts.filter(x => x.codeDesc === codeType)" :key="px.cptId">
            <td>
              {{ px.originalCPT }}{{ px.originalModifierCalc }}
              <span v-if="px.procedureDate" style="display: inline-block; font-style: italic; margin-top: 5px; font-size: 13px;">({{ formatDate(px.procedureDate) }})</span>
            </td>
            <td>
              {{ px.origDesc }} <span v-if="px.originalGrouperFlags" class="grouperFlags">({{ px.originalGrouperFlags }})</span>
            </td>

            <td :style="px.originalDeleted ? 'text-decoration: line-through' : ''">
              <template v-if="px.originalDeleted"> {{ px.originalCPT }}{{ px.originalModifierCalc }} </template>
              <template v-else>
                <span v-html="px.calcRecCPT"></span>
              </template>
            </td>

            <td>
              {{ px.originalDeleted ? getCptDeletedDescription(px) : px.recDesc }}
            </td>
            <td>{{ px.reasonForChangeDescription }}</td>
          </tr>
        </template>
      </tbody>
    </table>

    <table class="" id="codingRec">
      <tr style="background-color: lightslategray; color: white; text-align: center;">
        <td style="width: 100%;">E&M Scoring</td>
      </tr>
    </table>

    <table>
      <tr v-if="worksheet.scoring && worksheet.scoring.showScoringSheet">
        <td width="95%">
          <ProWorksheetHistory class="subTable" v-if="!worksheet.decisionMaking2021 && !worksheet.decisionMaking2023" :scoring="worksheet.scoring" :worksheet="worksheet">
          </ProWorksheetHistory>
          <ProWorksheetPhysicalExam class="subTable" v-if="!worksheet.decisionMaking2021 && !worksheet.decisionMaking2023" :scoring="worksheet.scoring" :worksheet="worksheet">
          </ProWorksheetPhysicalExam>
          <ProWorksheetMDM class="subTable" v-if="!worksheet.decisionMaking2021 && !worksheet.decisionMaking2023" :scoring="worksheet.scoring" :worksheet="worksheet">
          </ProWorksheetMDM>
          <ProWorksheetMDM2021 class="subTable" v-if="worksheet.decisionMaking2021 || worksheet.decisionMaking2023" :scoring="worksheet.scoring" :worksheet="worksheet">
          </ProWorksheetMDM2021>
          <ProWorksheetHistory class="subTable" v-if="worksheet.decisionMaking2021 || worksheet.decisionMaking2023" :scoring="worksheet.scoring" :worksheet="worksheet">
          </ProWorksheetHistory>
          <ProWorksheetPhysicalExam class="subTable" v-if="worksheet.decisionMaking2021 || worksheet.decisionMaking2023" :scoring="worksheet.scoring" :worksheet="worksheet">
          </ProWorksheetPhysicalExam>
        </td>
      </tr>
      <tr v-else>
        <td><i>E&M Scoring Not Specified</i></td>
      </tr>
    </table>

    <template>
      <table class="" id=" findings">
        <tr style="background-color: lightslategray; color: white; text-align: center;">
          <th style="width: 100%;">Findings</th>
        </tr>
      </table>

      <table>
        <tr>
          <td width="95%">
            <ProWorksheetFindings :findings="worksheet.findings" :decisionMaking2021="worksheet.decisionMaking2021"> </ProWorksheetFindings>
          </td>
        </tr>
      </table>
    </template>

    <template>
      <table class="" id=" findings">
        <tr style="background-color: lightslategray; color: white; text-align: center;">
          <th style="width: 100%;">Checklists</th>
        </tr>
      </table>

      <table v-if="worksheet.checklists">
        <tr>
          <td width="95%">
            <ProWorksheetChecklists :checklists="worksheet.checklists"> </ProWorksheetChecklists>
          </td>
        </tr>
      </table>
    </template>

    <template v-if="worksheet.actionTaken || worksheet.locationOfSuppDoc || worksheet.codingRef">
      <table class="" id="codingRec">
        <tr style="background-color: lightslategray; color: white; text-align: center;">
          <th style="width: 100%;">Coding Recommendations</th>
        </tr>
      </table>

      <table width="100%" id="actionTaken">
        <thead>
          <tr style="text-align: center;">
            <th>Action Taken</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div v-html="getRichTextHtml(worksheet.actionTaken)"></div>
            </td>
          </tr>
        </tbody>
      </table>
      <table width="100%" id="locSupDoc">
        <thead>
          <tr style="text-align: center;">
            <th style="border-top: none;">Location of Supporting Doc</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div v-html="getRichTextHtml(worksheet.locationOfSuppDoc)"></div>
            </td>
          </tr>
        </tbody>
      </table>
      <table width="100%" id="codingRef">
        <thead>
          <tr style="text-align: center;">
            <th style="border-top: none;">Coding Reference</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div v-html="getRichTextHtml(worksheet.codingRef)"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <table class="demographics" width="100%" id="coderResponses" v-if="!isCoder && !optionalParameters.hideWorksheetCoderResponses">
      <tbody>
        <tr style="background-color: lightslategray; color: white; text-align: center;">
          <th colspan="6">Coder/Provider Responses</th>
        </tr>
        <tr v-if="!worksheet.rebuttal">
          <td colspan="6">
            <div style="font-style: italic; font-size: 14px;">No Responses on Worksheet</div>
          </td>
        </tr>
        <template v-if="worksheet.rebuttal">
          <tr>
            <td class="strong">
              Response Date:
            </td>
            <td>
              {{ formatDate(worksheet.rebuttal.rebuttalDate) }}
            </td>
            <td class="strong">
              Coder/Provider Agrees With Changes:
            </td>
            <td>
              {{ worksheet.rebuttal.agreeWithCodes ? 'Yes' : 'No' }}
            </td>
            <td class="strong">
              Final Outcome:
            </td>
            <td>
              {{ worksheet.rebuttal.finalOutcome }}
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div class="strong" style="margin-bottom: 20px;">Response Comments:</div>
              <div style="margin: 0px 20px 0px 20px;" v-for="comment in worksheet.rebuttal.rebuttalComments" :key="comment.commentId">
                {{ comment.user.fullName }} on <i>{{ format(parseISO(comment.createdDate), 'M/d/yyyy hh:mm a') }}</i>
                <div class="commentRichText" v-html="comment.comment"></div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
  import dateHelper from '@/mixins/date-helper'
  import { format, parseISO } from 'date-fns'
  import ProWorksheetHistory from './ProWorksheetHistory.vue'
  import ProWorksheetPhysicalExam from './ProWorksheetPhysicalExam.vue'
  import ProWorksheetMDM from './ProWorksheetMDM.vue'
  import ProWorksheetMDM2021 from './ProWorksheetMDM2021.vue'
  import ProWorksheetFindings from './ProWorksheetFindings.vue'
  import ProWorksheetChecklists from './ProWorksheetChecklists.vue'

  export default {
    name: 'ProWorksheet',
    mixins: [dateHelper],
    // props: ['worksheet', 'isCoder', 'optionalParameters'],
    props: {
      worksheet: {
        type: Object
      },
      isCoder: {
        type: Boolean
      },
      optionalParameters: {
        type: Object,
        default: function () {
          return {}
        }
      }
    },
    components: {
      ProWorksheetHistory,
      ProWorksheetPhysicalExam,
      ProWorksheetMDM,
      ProWorksheetMDM2021,
      ProWorksheetFindings,
      ProWorksheetChecklists
    },
    data() {
      return {
        format: format,
        parseISO: parseISO,
        complianceIssues: ['Documentation Improvement', 'Signature Requirements Not Met']
      }
    },
    methods: {
      isDxRowChange(dx) {
        if (dx.recommendedDx || dx.originalDeleted || dx.recommendedPOA) {
          return true
        }
        return false
      },
      isPxRowChange(px) {
        if (px.recommendedCpt || px.originalDeleted) {
          return true
        }
        return false
      },
      getRichTextHtml(richText) {
        if (richText) {
          return richText
        }
        return '<i>none</i>'
      },
      getCodeTypes(arr) {
        const codeTypes = [...new Set(arr.map(x => x.codeDesc))]
        return codeTypes.sort((a, b) => (a > b ? 1 : -1))
      },
      getDxRecommendedDescription(dx, dxs) {
        if (dx.originalDeleted) {
          if (dx.recommendedDesc) {
            return `Delete Diagnosis - ${dx.recommendedDesc}`
          }
          return 'Delete Diagnosis'
        }

        if (dx.recommendedDesc && dx.rowNumber === 1 && dxs.some(x => x.newPrimary)) {
          if (dx.recommendedDesc) {
            return `Resequence Principal Diagnosis - ${dx.recommendedDesc}`
          }
          return 'Resequence Principal Diagnosis'
        }

        return dx.recommendedDesc
      },
      getCptDeletedDescription(cpt) {
        if (cpt.origDesc) {
          return `Delete CPT - ${cpt.origDesc}`
        }
        return 'Delete CPT'
      },
      isOriginalRedFont(dx, dxs) {
        if (dx.rowNumber === 1 && dxs.some(x => x.newPrimary && x.originalDeleted)) {
          return true
        }

        if (dx.originalDeleted && !dxs.some(x => x.newPrimary)) {
          return true
        }
        return false
      },
      isRecommendedRedFont(dx, dxs) {
        return dx.originalDeleted
      }
    }
  }
</script>

<style scoped>
  >>>.scoringHeader {
    color: rgba(0, 0, 0, 0.9);
    background-color: lightgray;
    font-weight: 500;
  }

  >>>.subTable table {
    margin-bottom: 4px;
  }

  .commentRichText {
    border: 1px solid #dcdfe6;
    background: #e5eef4;
    border-radius: 4px;
    padding: 5px;
    margin: 10px 0 20px 0px;
    min-height: 30px;
  }

  .codeTypeHeader {
    text-align: left;
    padding: 4px 0px 4px 5px;
    border-left: 8px solid #3378aa7c;
    font-style: italic;
  }

  .strong {
    font-weight: bold;
  }

  .separator {
    height: 15px;
    background-color: lightslategray;
  }

  .red-font {
    color: red;
  }

  .bold {
    font-weight: bold;
  }

  tr:nth-child(odd) {
    background: unset;
  }

  .worksheet thead {
    /* background-color: unset !important; */
  }

  .grouperFlags {
    font-style: italic;
  }

  >>>.delCode {
    color: red;
    text-decoration: line-through;
  }
</style>